<template>
    <div class="SearchList">
        <div class="hd">
            <Search
                    v-model="showhotsearch"
                    show-action
                    placeholder="请输入搜索关键词"
                    @input="oninput"
                    @search="onSearch"
                    @cancel="onCancel"
            />
        </div>
        <div class="hotsearch" v-if="!Searchvalue">
            <div class="tit">热门搜索：</div>
            <CellGroup>
                <Cell title="射手座" />
                <Cell title="射手座" />
                <Cell title="射手座" />
            </CellGroup>
        </div>
        <div class="bd">
            <div class="DropdownMenu">
                <DropdownMenu>
                    <DropdownItem v-model="value1" :options="option1" />
                    <DropdownItem v-model="value2" :options="option2" />
                    <DropdownItem v-model="value3" :options="option3" />
                </DropdownMenu>
            </div>
            <div class="list" v-if="goodslist.length">
                <div class="item" v-for="(item, index) in goodslist" :key="index" @click="goPages('/Tasting/goods/detail?id=1')">
                    <div class="pic">
                        <VanImage width="100%" height="100%" fit="cover" lazy-load  src="" />
                    </div>
                    <div class="rcon">
                        <div class="fen">
                            <div class="number">
                                <span class="font-color">10</span>分
                            </div>
                            <div class='starsList'>
                                <i data-index='1' class="iconfont iconxingji font-color-cur"></i>
                                <i data-index='2' class="iconfont iconxingji font-color-cur"></i>
                                <i data-index='3' class="iconfont iconxingji font-color-cur"></i>
                                <i data-index='4' class="iconfont iconxingji font-color-cur"></i>
                                <i data-index='5' class="iconfont iconxingji font-color-cur"></i>
                            </div>
                        </div>
                        <div class='name Ellipses1'>月光女神 半甜干红葡萄酒</div>
                        <div class='desc Ellipses1'>智利 | 莫莱山谷 | 半甜型 | 梅洛</div>
                        <div class='money font-color'>￥<span class='num'>98.00</span>
                            <span class="font-color">/瓶</span>
                            <span class="num2">10人付款</span>
                            <span class="quan border-color font-color">满10,000减1,500</span>
                        </div>
                        <div class="label3">
                            <span>节日用酒</span><span>宴请用酒</span>
                            <span>配菜：奶酪</span>
                            <span>酒龄：全年龄段</span>
                            </div>
                        <div class="label2">
                            <span>本市发货</span>
                            <span>2小时到达</span>
                            距离28公里
                        </div>
                        <div class="label3">
                            <span class="iconfont iconzan">智利前二十大</span>
                        </div>
                    </div>
                </div>
            </div>
            <Empty description="暂无数据" v-else/>
        </div>

        <Backtop></Backtop>
    </div>
</template>

<script>
    import {Search, Toast, Empty, Cell, CellGroup, DropdownMenu, DropdownItem} from 'vant';
    import { Image as VanImage } from 'vant';
    import Backtop from "./components/Backtop";
    export default {
        name: "SearchList",
        data() {
            return {
                showhotsearch: '',
                Searchvalue: '',

                value1: 0,
                value2: 'a',
                value3: 'a',
                option1: [
                    { text: '综合排序', value: 0 },
                ],
                option2: [
                    { text: '价格', value: 'a' },
                    { text: '从低到高', value: 'b' },
                    { text: '从高到低', value: 'c' },
                ],
                option3: [
                    { text: '销量', value: 'a' },
                    { text: '从低到高', value: 'b' },
                    { text: '从高到低', value: 'c' },
                ],

                goodslist: [0,1,2]
            };
        },
        components: {
            Backtop, VanImage, Search, Empty, Cell, CellGroup, DropdownMenu, DropdownItem
        },
        methods: {
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            oninput(val) {
                if(!val){
                    this.Searchvalue='';
                }
            },
            onSearch(val) {
                this.Searchvalue=val;
                Toast(val);
            },
            onCancel() {
                Toast('取消');
                this.$router.back(-1);
            },
        },
    }
</script>

<style scoped lang="scss">
.SearchList{
    padding-top:1.1rem;font-size:0.24rem;
    .van-search__content{border: 1px solid #eee;}
    .hd{
        position: fixed;top: 0;left: 0;right: 0;background: #ffff;z-index: 1;border-bottom:1px solid #eee;height:1.1rem;overflow: hidden;
    }
    .hotsearch{
        position: fixed;top: 1.1rem;left: 0;right: 0;bottom:0;background: #ffff;z-index: 11;padding:0.2rem;overflow-y: scroll;
        .tit{color:#666;font-size: 0.28rem;border-bottom:1px solid #eee;padding: 0.2rem 0;}
        .van-cell{padding: 0.2rem 0;border-bottom:1px solid #eee;}
    }
    .bd{
        padding-top:1.1rem;
        .DropdownMenu{
            position: fixed;top: 1.1rem;left: 0;right: 0;background: #ffff;z-index: 1;
        }
        .list{
            background:#fff;
            .item{
                display: flex;padding: 0.2rem;border-bottom: 1px solid #eee;
                .pic{width:1.6rem;height:2rem;flex:none;}
                .rcon{
                    flex: 1;padding-left:0.2rem;color:#999;
                    .fen{
                        overflow:hidden;
                        .number{
                            float:left;
                            span{font-size:0.36rem;margin-right:0.05rem;}
                        }
                        .starsList{
                            margin-left:0.1rem;float:left;margin-top: 0.04rem;
                            i{font-size: 0.3rem;color: #dadada;}
                            .font-color-cur{color: #ec652b;}
                        }
                    }
                    .name{font-size:0.28rem;color:#333;width:5rem;}
                    .desc{margin:0.03rem 0;width:5rem;}
                    .money{
                        margin: 0.08rem 0;
                        .num{font-size:0.32rem;}
                        .num2{color:#999;margin: 0 0.1rem;}
                        .quan{padding: 0 0.1rem;border-radius: 0.05rem;}
                    }
                    .label3{
                        margin: 0.1rem 0;
                        span{display: inline-block;margin-right: 0.1rem;font-size:0.24rem;}
                    }
                    .label2 {
                        background: #f5f5f5;
                        padding: 0.1rem;
                        overflow: hidden;
                        text-align: right;
                        color: #999;
                        span {
                            border-radius: 0.1rem;
                            border: 1px solid #eee;
                            padding: 0 0.1rem;
                            margin-right: 0.1rem;
                            display: inline-block;
                            border: 1px solid #6d8fff;
                            color: #6d8fff;
                            float: left;
                        }
                    }
                }
            }
        }
    }
}
</style>